body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: black;
    color: white;
    padding: 2rem;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.red {
    background-color: red;
}

.green {
    background-color: green;
}

.modal-dialog {
    color: black
}

.menuButtonOpen {
    border-radius: 0.25rem 0.25rem 0 0;
}

.menuButtonClosed {
    border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;
}

.menuOptionButton {
    border-radius: 0;
}

.menuOptionButtonLast {
    border-radius: 0 0 0.25rem 0.25rem;
    ;
}