.itemTable{
    color: white;
}

.accordion-button, .accordion-button:not(.collapsed){
    color: white;
    background-color: #6c757d;

}

.accordion-body{
    background-color: #6C757D;
}

.consoleHistory:disabled, .consoleCommand, .consoleCommand:focus{
    background-color: black;
    font-family: monospace;
    color: #a6e22e;
}

.consoleHistory{
    border: none;
    border-radius: 0;
}

.commandCarrot{
    background-color: black;
    font-family: monospace;
    /* color: #F92672; */
    color: white;
    border: none;
    padding: .375rem .75rem;
}

.consoleCommand, .consoleCommand:focus{
    border: none;
    display: inline;
    padding-left: 0;
    box-shadow: none;
}

.craftButton{
    padding: 0 0.375rem 0 0.375rem;
    vertical-align: top;
}

.commandCarrot.topCommand{
    border-radius: 0.25rem 0 0 0;
    width: 15%;
}

.commandCarrot.bottomCommand{
    border-radius: 0 0 0 0.25rem;
    width: 4%;
}

.consoleCommand.topCommand{
    border-radius: 0 0.25rem 0 0;
    width: 85%;
}

.consoleCommand.bottomCommand{
    border-radius: 0 0 0.25rem 0;
    width: 96%;
}