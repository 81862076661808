.passButtonsGrid{
    padding: 0.2rem;
}

.passButtons{
    color: white;
    border-color: white;
}

.bottomDiv{
    position: fixed;
    bottom: 0;    
    left: 0;
}